import { UserRound } from 'lucide-react'
import React from 'react'

export default function ReviewCard({name,description,lang}) {
    return (
        <div className="flex flex-col items-center bg-base-100 rounded-2xl w-full">
            <div className='w-full flex items-center rounded-2xl p-2 py-3'>
                <UserRound className='mr-2' />
                <h3 className="text-lg font-extrabold">{name}</h3>
            </div>
            <p className='p-3 mb-1 text-sm'>{description}</p>
        </div>
    )
}
