import { ExternalLink } from 'lucide-react';
import React from 'react'
import { Link } from 'react-router-dom';

export default function ProjectCard({name,description,url,lang,img,back=false}) {
    return (
        <div className='flex flex-col items-center bg-base-100 rounded-2xl max-w-2xls w-full h-min'>
            <Link to={url} className='w-full flex justify-between rounded-2xl hover:bg-base-300 p-2 py-3 hover:scale-[1.05] duration-200' target='_blank'>
                <div className='flex items-center'>
                    <img className={'w-6 mr-2 '+(back?'bg-black rounded-full':'')} src={require("../img/"+img+".png")} alt={img+' logo'}/>
                    <h3 className="text-center text-lg font-extrabold">{name}</h3>
                </div>
                <ExternalLink />
            </Link>
            <p className="p-3 mb-1 text-sm">{description[lang]}</p>
        </div>
    )
}
