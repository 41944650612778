import React from 'react'
import Projects from './Projects';
import Reviews from './Reviews';
import About from './About';

export default function Home({lang}) {
    return(
        <div className='bg-base-200 flex flex-col lg:flex-row p-4s min-h-screens overflow-hidden'>
            <section className="flex flex-col bg-base-300_ w-full lg:w-2/5 p-3 rounded-lg gap-4 lg:m-4 lg:mt-40">
                <About lang={lang} />
            </section>
            <section className='w-full lg:w-3/5 p-3 flexs flex-cols lg:h-screen lg:overflow-scroll'>
                <Projects lang={lang} />
                <Reviews lang={lang} />
            </section>
        </div>
    )
}