import React from 'react'
import L from "./L.json"
import ReviewCard from './ReviewCard'

export default function Reviews({lang,handleLangChange}) {
    return (
        <>
            <h1 className="text-3xl mt-10 mb-5 font-extrabold">Reviews</h1>
            <div className="flex flex-col md:grid md:grid-cols-2 gap-8 h-screenss">
                {Object.keys(L.reviews.reviewCards).map(project=>{
                    return <ReviewCard key={project} name={project} description={L['reviews']['reviewCards'][project]} />
                })}
            </div>
        </>
    )
}
