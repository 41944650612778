import React from 'react'
import L from "./L.json"
import { Link } from 'react-router-dom';
import { 
    // Contact,
    // DollarSign,
    Github,
    Instagram,
    Linkedin,
    // Mail,
    MapPin,
    // Phone,
    Twitter
} from 'lucide-react';

export default function About({lang}) {
    /* const generateVCard = () => {
        const contact = {
            firstName: 'Jonas',
            lastName: 'Kaatz',
            organization: 'Software Developer',
            email: 'hi@jonaskaatz.com',
            url: 'https://jonaskaatz.com',
        };
        const vCardString = `BEGIN:VCARD\nVERSION:3.0\nN:${contact.lastName};${contact.firstName};;;\nORG:${contact.organization}\nEMAIL;TYPE=PREF,INTERNET:${contact.email}\nURL:${contact.url}\nNOTE:${contact.note}\nEND:VCARD`.trim();
        const blob = new Blob([vCardString], { type: 'text/vcard' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'jonaskaatz.vcf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }; */
    return (
        <>
            <div className='flex lg:flex-col lg:items-center gap-4'>
                    <img className='rounded-full w-32 h-32' src={require("../img/jk.jpg")} alt="Logo"/>
                    <div className='flex flex-col justify-center sw-2/3'>
                        <div className='flex flex-col lg:items-center'>
                            <h1 className="text-4xl font-extrabold">Jonas Kaatz</h1>
                            <div className='flex flex-col lg:flex-row lg:gap-4'>
                                <p className="text-sm">{L['job'][lang]}</p>
                                <div className='flex items-center gap-1'>
                                    <MapPin size={16} />
                                    <p className="text-sm"> {L['location'][lang]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <p className='mt-5'>Small Text</p> */}
                <div className='divider'></div>
                <div className="flex justify-around">
                    <Link className='btn btn-ghost btn-square' to={'https://x.com/jonaskaatz'} target='_blank' ><Twitter /></Link>
                    <Link className='btn btn-ghost btn-square' to={'https://github.com/Jonas1220'} target='_blank' ><Github /></Link>
                    <Link className='btn btn-ghost btn-square' to={'https://www.linkedin.com/in/jonaskaatz/'} target='_blank' ><Linkedin/></Link>
                    <Link className='btn btn-ghost btn-square' to={'https://www.instagram.com/jonask_95/'} target='_blank' ><Instagram/></Link>
                    {/* {lang==='de'? <Link className='btn btn-ghost btn-square' to={'https://donate.stripe.com/cN2bKk6Tn6Mg2hW3cf'} target='_blank' ><DollarSign/></Link> : <Link className='btn btn-ghost btn-square' to={'https://donate.stripe.com/dR66q0b9D3A4g8MfZ0'} target='_blank' ><DollarSign/></Link> } */}
                    {/* <Link className='btn btn-ghost btn-square' to={'mailto:hi@jonaskaatz.com'} ><Mail /></Link> */}
                    {/* <button className='btn btn-ghost btn-square' onClick={generateVCard} ><Contact /></button> */}
                    {/* <Link className='btn btn-ghost btn-square' to={'tel:+4915221994132'} ><Phone /></Link> */}
                </div>
        </>
    )
}
