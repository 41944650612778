import Home from './components/Home';
import detectBrowserLanguage from 'detect-browser-language'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// https://preview.colorlib.com/#meetme

const LOCAL_STORAGE_LANG='language';

function App() {
    const lang=(localStorage.getItem(LOCAL_STORAGE_LANG)==='de'||localStorage.getItem(LOCAL_STORAGE_LANG)==='en')?localStorage.getItem(LOCAL_STORAGE_LANG):(detectBrowserLanguage().substring(0,2)==='de')?'de':'en'
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home lang={lang} />} />
                <Route path='*' element={ <Navigate to={'/'}/> } />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
