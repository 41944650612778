import React from 'react'
import ProjectCard from './ProjectCard'
import L from "./L.json"

export default function Projects({lang,handleLangChange}) {
    return (
        <>
            <h1 className="text-3xl lg:mt-10 mb-5 font-extrabold">Projects</h1>
            <div className='flex flex-col md:grid md:grid-cols-2 gap-8 h-screens soverflow-scroll '>
                {Object.keys(L.projects.projectCards).map(project=>{
                        return <ProjectCard key={project} name={project} description={L['projects']['projectCards'][project]} url={L['projects']['projectCards'][project]['url']} lang={lang} img={L['projects']['projectCards'][project]['img']} back={L['projects']['projectCards'][project]['background']} />
                    })}
            </div>
        </>
    )
}
